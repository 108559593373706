import React from 'react';
import Layout from '../components/App/Layout';
import Navbar from '../components/App/Navbar';
import PageBanner from '../components/Common/PageBanner';
import Footer from '../components/App/Footer';
import {Link} from 'gatsby';
import SEO from '../components/App/SEO';

const PrivacyPolicy = () => {
    return (
        <Layout>
            <SEO
              title="特定商取引に基づく表記"
              desc="特定商取引に基づく表記です。"
              pathname={'/legal'}
              type={'Article'}
              shortName="特定商取引に基づく表記"
              createdAt="2021-03-02"
              updatedAt="2021-03-02"
            />
            <Navbar />
            <PageBanner
                pageTitle="特定商取引に基づく表記"
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="特定商取引に基づく表記"
            />
            <section className="privacy-policy-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="privacy-policy-content">
                                <h3>販売業者</h3>
                                <p>株式会社リタッチ</p>
                                <h3>運営統括責任者</h3>
                                <p>代表取締役社長 福嶋英雄</p>
                                <h3>所在地</h3>
                                <p>
                                    〒541-0053<br/>
                                    大阪府大阪市中央区本町４丁目４−１７ RE-012 3階
                                </p>
                                    <h3>お問い合わせ先</h3>
                                <p>
                                    <Link to={"https://retach.biz/contact"}> https://retach.biz/contact</Link><br/>
                                    ※サービスや取引に関するお問い合わせは電話では受け付けておりません。お問い合わせについては個人情報保護などのためサイト内の「お問い合わせ」フォームよりご連絡をお願いいたします。<br/>
                                    090-4628-3127
                                </p>
                                <h3>販売価格</h3>
                                <p>料金プランは、サービスの料金ページをご覧ください。</p>
                                <h3>その他お客様の負担する費用</h3>
                                <p>
                                    インターネット利用のために必要となる通信料及び接続料<br/>
                                    （金額は、お客様が契約した各事業者にお問い合わせください。）
                                </p>
                                <h3>代金の支払方法・時期</h3>
                                <p>
                                    ＜都度課金＞<br/>
                                    クレジットカード：購入時<br/>
                                    ＜定期課金＞<br/>
                                    クレジットカード：初回購入時、契約プランによって定められた契約期間満了時に都度更新請求
                                </p>
                                <h3>商品引渡し又はサービス提供の時期</h3>
                                <p>登録手続の完了後直ちに提供します。ただし、銀行振込の場合、入金確認後当社審査完了次第速やかに提供します。</p>
                                <h3>返品等の対応</h3>
                                <p>
                                    商品の特性上、返品には応じられず、購入確定後のキャンセル・返金についてはお受けできません。<br/>
                                    定期購入のキャンセルにつきましては、管理画面より定期購入のキャンセルを行ってください。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
};

export default PrivacyPolicy;